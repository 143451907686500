import { signIn } from "next-auth/react";
import classes from "./LoginPageComponent.module.scss";
import Image from "next/image";

import hddLogo from "./assets/images/logo-huge-ding-dong.svg";
import { hideWelcomeScreenLocalStorageKey } from "./WelcomeScreen";

export default function LoginPageComponent(props) {
  // if user opted to hide welcome screen, do not show it
  const login = (e) => {
    const callbackUrl =
    localStorage.getItem(hideWelcomeScreenLocalStorageKey) !== "1"
      ? `${window.location.origin}/app?signed=true`
      : "";

    signIn("cognito", {
      callbackUrl: callbackUrl,
    });
  };

  return (
    <>
      <div className={classes.main}>
        <Image
          src={hddLogo}
          alt="Huge Ding Dong Logo"
          width={300}
          height={60}
          className={classes.logo}
        />
        <h1 className={classes.helloText}>Hello, please log in</h1>
        <button className={classes.loginBtn} onClick={login}>
          Log in
        </button>
      </div>
    </>
  );
}
