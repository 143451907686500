import classes from "./DingDongPageComponent.module.scss";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useState } from "react";

export const hideWelcomeScreenLocalStorageKey = "hdd_hide_welcome";

export default function WelcomeScreen(props) {
  const { data: session, status } = useSession();
  const router = useRouter();

  const [isWelcomeScreenDisabled, setIsWelcomeScreenDisabled] = useState(
    localStorage.getItem(hideWelcomeScreenLocalStorageKey) === "1"
  );

  const { pathname, query } = router;

  const cleanReload = () => {
    // remove query params and show week view
    router.replace(pathname, undefined, { shallow: true });

    setTimeout(() => {
      location.reload();
    }, 0);
  };

  const welcomeDontShow = (e) => {
    const checkbox = e.target;
    if (checkbox.checked) {
      localStorage.setItem(hideWelcomeScreenLocalStorageKey, 1);
      setIsWelcomeScreenDisabled(true);
    } else {
      localStorage.removeItem(hideWelcomeScreenLocalStorageKey);
      setIsWelcomeScreenDisabled(false);
    }
  };

  return (
    <div className={classes.welcome}>
      <h1>Hello {session.user.name}!</h1>
      <p>
        If it is your first time here, you probably need to copy generated API
        KEY and use it in{" "}
        <a href="https://app.asana.com/" target="_blank" rel="noreferrer">
          Asana
        </a>{" "}
        plugin to connect it to this app.
      </p>
      <p>
        <strong>Your API KEY:</strong>
      </p>
      <p className={classes.codeWrapper}>
        <code className={classes.code}>{session.user.api_key}</code>
        <small>
          You can always find your api key on your profile page, by clicking on
          your name in the topbar
        </small>
      </p>
      <p>
        <button onClick={cleanReload} className={classes.welcomeBtn}>
          Understood, take me to the app
        </button>
      </p>
      <label className={classes.welcomeDontShow}>
        <input
          type="checkbox"
          onChange={welcomeDontShow}
          defaultChecked={isWelcomeScreenDisabled}
        />
        Don{"'"}t show that screen again
      </label>
    </div>
  );
}
